import React, { useEffect } from 'react'
import { Platform } from 'react-native'
import Iframe from 'react-iframe'
import { WebView } from 'react-native-webview'
import PropTypes from 'prop-types'

export const WebViewRender = ({
  url,
  onNavigationStateChange,
  onMessage,
  position = 'absolute',
}) => {
  useEffect(() => {
    if (Platform.OS === 'web') {
      window.addEventListener('message', onMessage)
    }

    return () => {
      if (Platform.OS === 'web') {
        window.removeEventListener('message', onMessage, false)
      }
    }
  }, [])

  if (Platform.OS === 'web') {
    return (
      <Iframe
        url={url}
        position={position}
        width='100%'
        height={'100%'}
        frameBorder='0'
        loading='eager'
      />
    )
  }

  const runFirst = `
        window.isNativeApp = true;
        true; // note: this is required, or you'll sometimes get silent failures
      `

  return (
    <WebView
      originWhitelist={['*']}
      source={{ uri: url }}
      scrollEnabled={true}
      incognito={true}
      injectedJavaScriptBeforeContentLoaded={runFirst}
      onNavigationStateChange={onNavigationStateChange}
      onMessage={onMessage}
    />
  )
}

WebViewRender.propTypes = {
  url: PropTypes.string.isRequired,
  onNavigationStateChange: PropTypes.func,
  onMessage: PropTypes.func,
  position: PropTypes.string,
}
