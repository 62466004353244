import React from 'react'
import { Linking, Platform, SafeAreaView, StyleSheet } from 'react-native'
import { WebViewRender } from '@modules/webView/components'
import LoaderCenterContainer from '@components/loaders/LoaderCenterContainer'
import { usePolygonsTrader } from '../../hooks'

const PolygonsTrader = () => {
  const { urlWebView } = usePolygonsTrader()

  const handleIFrameMessages = (event: any) => {
    try {
      const dataStr =
        Platform.OS === 'web' ? event.data : event.nativeEvent.data

      if (dataStr) {
        const data = JSON.parse(dataStr)

        if (data?.url) {
          Linking.openURL(data.url)
        }
      }
    } catch (error) {}
  }

  return (
    <SafeAreaView style={styles.container}>
      {!urlWebView ? (
        <LoaderCenterContainer />
      ) : (
        <WebViewRender url={urlWebView} onMessage={handleIFrameMessages} />
      )}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

export default PolygonsTrader
