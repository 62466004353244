import { StyleSheet, Text, View } from 'react-native'
import { List } from 'react-native-paper'
import { blackDisabled, carbon400 } from '@styles/palette'
import { gray200 } from '@modules/common/styles/palette'

const DrawerItem = ({
  disable,
  title,
  titleStyle = {},
  description,
  onPress,
  containerItemStyle = {},
  itemStyle = {},
  left,
  right,
  active = false,
  label = undefined,
  descriptionStyle = undefined,
  onlyIcon = undefined,
}: any) => {
  const handleNotConnectionOrIsPendingRequest = () => {
    console.warn('Not connection or pending for authorization')
  }

  return (
    <View style={[containerItemStyle, active ? styles.active : styles.empty]}>
      {Boolean(label) && <Text style={styles.label}>{label}</Text>}

      <List.Item
        onPress={!disable ? onPress : handleNotConnectionOrIsPendingRequest}
        title={!onlyIcon ? title : null}
        description={!onlyIcon ? description : null}
        left={left}
        right={!onlyIcon ? right : null}
        titleStyle={[titleStyle, disable ? styles.disabled : {}]}
        style={[styles.listItem, itemStyle]}
        descriptionStyle={[styles.description, descriptionStyle]}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  empty: {},
  listItem: {
    paddingVertical: 5.5,
  },
  disabled: {
    color: blackDisabled,
  },
  active: {
    backgroundColor: gray200,
    borderRadius: 8,
  },
  label: {
    fontSize: 10,
    fontWeight: '700',
    fontStyle: 'normal',
    marginLeft: 72.5,
    color: carbon400,
    marginBottom: -6,
  },
  description: {
    fontSize: 10,
    fontWeight: '400',
    fontStyle: 'normal',
    color: carbon400,
  },
})

/*

DrawerItem.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      cropId: PropTypes.string.isRequired,
    }),
  }),
  disable: PropTypes.bool,
  title: PropTypes.string.isRequired,
  titleStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.object, PropTypes.number])
    ),
  ]),
  description: PropTypes.string,
  onPress: PropTypes.func,
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.object, PropTypes.number])
    ),
  ]),
  left: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  right: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
}

*/

export default DrawerItem
